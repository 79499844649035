import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "radio",
      class: "form-checkbox",
      name: _ctx.id,
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleClick($event.target.checked)), ["stop"]))
    }, null, 8, _hoisted_2),
    _renderSlot(_ctx.$slots, "default")
  ]))
}