
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import Validation from "@/components/Validation.vue";
import { IonSlide, IonSlides } from "@ionic/vue";
import Form1 from "@/views/Onboarding/Forms/Form1.vue";
import Form2 from "@/views/Onboarding/Forms/Form2.vue";
import Form3 from "@/views/Onboarding/Forms/Form3.vue";
import Form4 from "@/views/Onboarding/Forms/Form4.vue";
import useVuelidate from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Form5 from "@/views/Onboarding/Forms/Form5.vue";
import Form6 from "@/views/Onboarding/Forms/Form6.vue";
import UserService from "@/services/UserService";
import { getCurrentInstance } from "vue";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";

export default defineComponent({
  name: "FullyCompleteRegistration",
  components: { Form6, Form5, Form4, Form3, Form2, Form1, Validation, GuestLayout, IonSlides, IonSlide, SchoolLogo },
  setup() {
    const slider = ref(null);
    const currentIndex = ref<number>(0);
    const progress = ref<number>(25);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const $toasted = getCurrentInstance()?.appContext.config.globalProperties.$toasted;

    // Form 1
    const form1 = reactive({
      address: null,
      postalCode: null,
      location: null,
      birthDate: null,
      birthPlace: null,
    });

    // const form2 = reactive({
    //   licenseClass: null,
    //   theoryCourse: null,
    //   branch: null,
    // });

    // const form3 = reactive({
    //   paymentWorkflow: null,
    // });

    const form4 = reactive({
      nationality: { iso3166dash1alpha2Code: "DE", nameInGerman: "Deutschland" },
      existingDrivingLicense: null,
      language: {
        iso639dash1Code: "ger",
        nameInGerman: "Deutsch",
      },
    });

    const form5 = reactive({
      freeTextImportantThings: null,
      freeTextFutureIntentions: null,
    });

    const form6 = reactive({
      salutation: null,
      firstName: null,
      lastName: null,
      mobilePhone: null,
      email: null,
    });

    const form1Validation = useVuelidate(
      {
        address: { required },
        postalCode: { required },
        location: { required },
        birthDate: { required },
        birthPlace: { required },
      },
      form1
    );

    // const form2Validation = useVuelidate(
    //   {
    //     licenseClass: { required },
    //   },
    //   form2
    // );
    //const form3Validation = useVuelidate({}, form3);
    const form4Validation = useVuelidate(
      {
        nationality: { required },
        existingDrivingLicense: {},
        language: { required },
      },
      form4
    );
    const form5Validation = useVuelidate({}, form5);
    const form6Validation = useVuelidate(
      {
        salutation: { required },
        firstName: { required, minLength: minLength(2) },
        lastName: { required, minLength: minLength(2) },
        mobilePhone: { required },
        email: { required, email },
      },
      form6
    );

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      noSwiping: true,
      noSwipingClass: "swiper-no-swiping",
      autoHeight: true,
    };

    const next = (value: number) => {
      currentIndex.value = value;
      //@ts-ignore
      slider.value.$el.slideTo(value);

      progress.value = calcProgressIndicator(currentIndex.value);
    };

    const registerAction = async (options: any) => {
      await store.dispatch("submit-registration/create", options);
    };

    const findDrivingSchoolName = async (options: any) => {
      await store.dispatch("driving-school/findOne", options);
    };
    const drivingSchoolName = computed(() => store.getters["driving-school/getDataItem"]);

    const registerLoading = computed(() => store.getters["submit-registration/getIsLoading"]);
    const registerSuccess = computed(() => store.getters["submit-registration/getSuccess"]);

    const getLicenseClass = computed(() => store.state.licenseClass);
    const getTheoryCourse = computed(() => store.state.theoryCourse);
    const getBranch = computed(() => store.state.branch);

    const onSubmit = async () => {
      form4Validation.value.$touch();

      if (!route.params.drivingSchool || form4Validation.value.$invalid) return;

      if (!getLicenseClass.value) {
        $toasted?.error("Please select license class");
        return;
      }

      const licenseForm = {
        licenseClass: { licenseClass: getLicenseClass.value?.name || null, keyNumber: null },
        theoryCourse: getTheoryCourse.value,
        branch: getBranch.value,
      };

      const data = Object.assign(
        {
          drivingSchoolId: route.params.drivingSchool,
          requestContract: true,
        },
        form1,
        licenseForm,
        //form3,
        form4,
        form5,
        form6
      );

      await registerAction({
        resource: "/submit-registration",
        hideSuccessMessage: true,
        data: data,
      });

      if (registerSuccess.value === true) {
        await router.push({ name: "WellDone" });
      }
    };

    const showBackBtn = computed(() => currentIndex.value > 0);

    // const publicTheoryCourses = async (licenseClass = "", branchId: null | number | undefined = null) => {
    //   await store.dispatch("public-info/getTheoryCourses", {
    //     drivingSchoolId: route.params.drivingSchool,
    //     licenseClass: licenseClass,
    //     branchId: branchId || null,
    //   });
    // };

    // const getPublicTheoryCourses = computed(() => store.getters["public-info/getTheoryCourses"]);
    //
    const publicLicenceClasses = async () => {
      await store.dispatch("public-info/getPreviousLicenceClasses", route.params.drivingSchool);
    };

    const getPublicLicenceClasses = computed(() => store.getters["public-info/getPreviousLicenceClasses"]);
    const getPublicLicenceClassesLoading = computed(() => store.getters["public-info/getPreviousLicenceClassesLoading"]);

    const publicLanguages = async () => {
      await store.dispatch("public-info/getLanguages");
    };

    const getPublicLanguages = computed(() => store.getters["public-info/getLanguages"]);
    const getPublicLanguagesLoading = computed(() => store.getters["public-info/getLanguagesLoading"]);

    const publicCountries = async () => {
      await store.dispatch("public-info/getCountries");
    };

    const getPublicCountries = computed(() => store.getters["public-info/getCountries"]);
    const getPublicCountriesLoading = computed(() => store.getters["public-info/getCountriesLoading"]);

    // const publicBranches = async () => {
    //   await store.dispatch("public-info/getBranches", route.params.drivingSchool);
    // };
    //
    // const getPublicBranches = computed(() => store.getters["public-info/getBranches"]);

    onMounted(() => {
      void publicLicenceClasses();
      void publicLanguages();
      void publicCountries();

      const user = UserService.getUser();
      if (user) {
        form6.firstName = user.firstName;
        form6.lastName = user.lastName;
        form6.email = user.email;
        form6.mobilePhone = user.mobilePhone;
      }

      findDrivingSchoolName({
        resource: "/driving-schools/name",
        id: route.params.drivingSchool,
      });
    });

    // const selectedCourse = ref<IPublicTheoryCourse | null>(null);
    // const selectedLicenseClass = ref<IBasicNamedDto | null>(null);
    // const selectedBranch = ref<IBasicNamedDto | null>(null);

    // const onSelectLicenseClass = (option: IBasicNamedDto | null) => {
    //   selectedLicenseClass.value = option;
    //   if (option && option.id) {
    //     void publicTheoryCourses(option.name, selectedBranch.value?.id);
    //   }
    // };
    //
    // const onSelectCourse = (option: IPublicTheoryCourse | null) => {
    //   selectedCourse.value = option;
    // };
    //
    // const onSelectBranch = (option: IBasicNamedDto | null) => {
    //   selectedBranch.value = option;
    //   if (selectedLicenseClass.value && option && option.id) {
    //     void publicTheoryCourses(selectedLicenseClass.value.name, option.id);
    //   }
    // };

    // const publicBranchesFilter = computed(() => {
    //   if (selectedCourse.value) return [selectedCourse.value.branch];
    //   return getPublicBranches.value;
    // });

    // const publicCoursesFilter = computed(() => {
    //   return getPublicTheoryCourses.value;
    // });

    // const licenseClassId = route.params?.licenseClass;
    // const theoryCourseId = route.params?.theoryCourse;
    // const licenseClassName = route.params?.licenseClassName;

    // if (isString(licenseClassId)) {
    //   form2.licenseClass = getPublicLicenceClasses.value.find((licenseClass: IBasicNamedDto) => licenseClass.id === Number(licenseClassId));
    //   selectedLicenseClass.value = {
    //     id: parseInt(licenseClassId),
    //     name: licenseClassName.toString(),
    //   };
    // }
    //
    // if (isString(theoryCourseId)) {
    //   form2.theoryCourse = getPublicTheoryCourses.value.find((course: IPublicTheoryCourse) => course.id === Number(theoryCourseId));
    // }

    const onClickBackBtn = () => {
      currentIndex.value--;

      next(currentIndex.value);
      progress.value = calcProgressIndicator(currentIndex.value);
    };

    const calcProgressIndicator = (index: number) => {
      return index * 33.3333333333;
    };

    const drivingSchoolId = computed(() => Number(route.params.drivingSchool));

    return {
      slideOpts,
      slider,
      next,
      form1Validation,
      //form2Validation,
      //form3Validation,
      form4Validation,
      form1,
      //form2,
      //form3,
      form4,
      onSubmit,
      onClickBackBtn,
      showBackBtn,
      getPublicLicenceClasses,
      getPublicCountries,
      getPublicLanguages,
      progress,
      currentIndex,
      form5,
      form6,
      form5Validation,
      form6Validation,
      registerLoading,
      //onSelectLicenseClass,
      //onSelectCourse,
      //onSelectBranch,
      drivingSchoolId,
      getPublicLanguagesLoading,
      getPublicCountriesLoading,
      getPublicLicenceClassesLoading,
      drivingSchoolName,
      findDrivingSchoolName,
    };
  },
});
