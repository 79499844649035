
import { defineComponent } from "vue";
import CustomRadio from "@/components/CustomRadio.vue";

export default defineComponent({
  name: "Form3",
  components: { CustomRadio },
  props: {
    v: {},
    data: {
      required: true,
    },
  },
  emits: ["next"],
  setup(props, { emit }) {
    const onNext = () => {
      //@ts-ignore
      props.v.$touch();
      //@ts-ignore
      if (props.v.$invalid) return;
      emit("next");
    };
    return {
      onNext,
    };
  },
});
