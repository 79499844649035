import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_2 = { class: "d-flex justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolLogo = _resolveComponent("SchoolLogo")!
  const _component_b_progress = _resolveComponent("b-progress")!
  const _component_Form5 = _resolveComponent("Form5")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_Form6 = _resolveComponent("Form6")!
  const _component_Form1 = _resolveComponent("Form1")!
  const _component_Form4 = _resolveComponent("Form4")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_GuestLayout = _resolveComponent("GuestLayout")!

  return (_openBlock(), _createBlock(_component_GuestLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SchoolLogo, { "driving-school-id": _ctx.drivingSchoolId }, null, 8, ["driving-school-id"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.currentIndex > 0 && _ctx.currentIndex < 3)
          ? (_openBlock(), _createBlock(_component_b_progress, {
              key: 0,
              value: _ctx.progress
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ion_slides, {
        ref: "slider",
        options: _ctx.slideOpts,
        class: "swiper-no-swiping"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_Form5, {
                v: _ctx.form5Validation,
                onNext: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next(1))),
                data: _ctx.form5
              }, null, 8, ["v", "data"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_Form6, {
                v: _ctx.form6Validation,
                onNext: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next(2))),
                onBack: _ctx.onClickBackBtn,
                data: _ctx.form6
              }, null, 8, ["v", "onBack", "data"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_Form1, {
                v: _ctx.form1Validation,
                onNext: _cache[2] || (_cache[2] = ($event: any) => (_ctx.next(3))),
                onBack: _ctx.onClickBackBtn,
                data: _ctx.form1
              }, null, 8, ["v", "onBack", "data"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_Form4, {
                v: _ctx.form4Validation,
                onSubmit: _ctx.onSubmit,
                data: _ctx.form4,
                loading: _ctx.registerLoading,
                "license-classes": _ctx.getPublicLicenceClasses,
                languages: _ctx.getPublicLanguages,
                nationalities: _ctx.getPublicCountries,
                "license-classes-loading": _ctx.getPublicLicenceClassesLoading,
                "languages-loading": _ctx.getPublicLanguagesLoading,
                "nationalities-loading": _ctx.getPublicCountriesLoading,
                onBack: _ctx.onClickBackBtn
              }, null, 8, ["v", "onSubmit", "data", "loading", "license-classes", "languages", "nationalities", "license-classes-loading", "languages-loading", "nationalities-loading", "onBack"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["options"])
    ]),
    _: 1
  }))
}