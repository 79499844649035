import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group text-left w-100 mt-5" }
const _hoisted_2 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_3 = { class: "form-group text-left w-100" }
const _hoisted_4 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_5 = { class: "form-group text-left w-100" }
const _hoisted_6 = { class: "font-size-18 font-weight-light font-color-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Validation = _resolveComponent("Validation")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createBlock(_component_b_row, {
    class: "w-100 justify-content-center form-height",
    style: _normalizeStyle(`--form-height:${_ctx.formHeight}px`)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_col, {
        cols: "12",
        sm: "10",
        md: "6",
        xl: "4",
        class: "d-flex align-items-center flex-column"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("students.desired_class")) + " * ", 1),
            _createVNode(_component_Validation, {
              class: "w-100",
              validations: _ctx.v.licenseClass,
              "show-errors": true
            }, {
              default: _withCtx(({ invalid }) => [
                _createVNode(_component_Multiselect, {
                  modelValue: _ctx.data.licenseClass,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.licenseClass) = $event)),
                  options: _ctx.licenseClasses,
                  "track-by": "id",
                  label: "name",
                  placeholder: _ctx.$t('calendar.option_placeholder'),
                  "select-label": _ctx.$t('calendar.enter_placeholder'),
                  "deselect-label": _ctx.$t('calendar.enter_remove'),
                  "selected-label": _ctx.$t('calendar.selected'),
                  class: _normalizeClass({ multiselect__invalid: invalid }),
                  onSelect: _ctx.onSelectLicenseClass,
                  onRemove: _ctx.onDeselectLicenseClass,
                  onOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onOpenMultiselect('theoryCourse', 0))),
                  onClose: _ctx.onCloseMultiselect,
                  maxHeight: 190,
                  openDirection: "bottom"
                }, null, 8, ["modelValue", "options", "placeholder", "select-label", "deselect-label", "selected-label", "class", "onSelect", "onRemove", "onClose"])
              ]),
              _: 1
            }, 8, ["validations"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("students.desired_course")), 1),
            _createVNode(_component_Multiselect, {
              modelValue: _ctx.data.theoryCourse,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.theoryCourse) = $event)),
              options: _ctx.theoryCourses,
              "track-by": "id",
              label: "name",
              placeholder: _ctx.$t('calendar.option_placeholder'),
              "select-label": _ctx.$t('calendar.enter_placeholder'),
              "deselect-label": _ctx.$t('calendar.enter_remove'),
              "selected-label": _ctx.$t('calendar.selected'),
              onSelect: _ctx.onSelectCourse,
              onRemove: _ctx.onDeselectCourse,
              onOpen: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOpenMultiselect('theoryCourse', 0))),
              onClose: _ctx.onCloseMultiselect,
              "custom-label": _ctx.nameWithStartDate,
              maxHeight: 190,
              openDirection: "bottom"
            }, null, 8, ["modelValue", "options", "placeholder", "select-label", "deselect-label", "selected-label", "onSelect", "onRemove", "onClose", "custom-label"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("students.desired_branch")), 1),
            _createVNode(_component_Multiselect, {
              modelValue: _ctx.data.branch,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.branch) = $event)),
              options: _ctx.branches,
              "track-by": "id",
              label: "name",
              placeholder: _ctx.$t('calendar.option_placeholder'),
              "select-label": _ctx.$t('calendar.enter_placeholder'),
              "deselect-label": _ctx.$t('calendar.enter_remove'),
              "selected-label": _ctx.$t('calendar.selected'),
              onSelect: _ctx.onSelectBranch,
              onRemove: _ctx.onDeselectBranch,
              onOpen: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onOpenMultiselect('theoryCourse', 110))),
              onClose: _ctx.onCloseMultiselect,
              openDirection: "bottom",
              maxHeight: 190
            }, null, 8, ["modelValue", "options", "placeholder", "select-label", "deselect-label", "selected-label", "onSelect", "onRemove", "onClose"])
          ]),
          _createElementVNode("button", {
            class: "further-btn mb-3",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)))
          }, "Weiter")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["style"]))
}