
import { defineComponent, onMounted, ref } from "vue";
import Validation from "@/components/Validation.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import moment from "moment";
import useHelper from "@/composable/useHelper";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  name: "Form2",
  components: { Validation, Multiselect: FscMultiselect },
  props: {
    v: {},
    data: {
      required: true,
    },
    licenseClasses: {
      default: () => [],
    },
    theoryCourses: {
      default: () => [],
    },
    branches: {
      default: () => [],
    },
  },
  emits: ["next", "on-select-license-class", "on-select-course", "on-select-branch"],
  setup(props, { emit }) {
    const { setScrollY, scrollToPoint, onOpenMultiselect, onCloseMultiselect } = useHelper();

    const formHeight = ref(140);

    const onNext = () => {
      //@ts-ignore
      props.v.$touch();
      //@ts-ignore
      if (props.v.$invalid) return;
      emit("next");
    };

    const onSelectLicenseClass = (option: any) => {
      emit("on-select-license-class", option);
    };

    const onSelectCourse = (option: any) => {
      emit("on-select-course", option);
    };

    const onSelectBranch = (option: any) => {
      emit("on-select-branch", option);
    };

    const onDeselectCourse = () => {
      emit("on-select-course", null);
    };

    const onDeselectBranch = () => {
      emit("on-select-branch", null);
    };

    const onDeselectLicenseClass = () => {
      emit("on-select-license-class", null);
    };

    const nameWithStartDate = (option: { name: string; startDate: string }) => {
      const formatDate = moment(option.startDate).format("DD.MM.YYYY");
      return `${option.name} - (${formatDate})`;
    };

    onMounted(() => {
      if (Capacitor.isNativePlatform()) {
        formHeight.value = 0;
      }
    });

    return {
      onNext,
      onSelectLicenseClass,
      onSelectCourse,
      onSelectBranch,
      onDeselectCourse,
      onDeselectBranch,
      onDeselectLicenseClass,
      nameWithStartDate,
      setScrollY,
      scrollToPoint,
      onOpenMultiselect,
      onCloseMultiselect,
      formHeight,
    };
  },
});
