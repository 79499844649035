import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group text-left w-100 mt-5" }
const _hoisted_2 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_3 = { class: "form-group text-left w-100" }
const _hoisted_4 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_5 = { class: "form-group text-left w-100" }
const _hoisted_6 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_7 = { class: "form-group text-left w-100" }
const _hoisted_8 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_9 = { class: "form-group text-left w-100" }
const _hoisted_10 = { class: "font-size-18 font-weight-light font-color-label" }
const _hoisted_11 = { class: "d-flex flex-row w-100 justify-content-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_Validation = _resolveComponent("Validation")!
  const _component_FscDatepicker = _resolveComponent("FscDatepicker")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createBlock(_component_b_row, { class: "w-100 justify-content-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_col, {
        cols: "12",
        sm: "10",
        md: "6",
        xl: "4",
        class: "d-flex align-items-center flex-column"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("user.street_no")) + " * ", 1),
            _createVNode(_component_Validation, {
              class: "w-100",
              validations: _ctx.v.address,
              "show-errors": true
            }, {
              default: _withCtx(({ invalid }) => [
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.data.address,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.address) = $event)),
                  trim: "",
                  class: _normalizeClass({ 'is-invalid': invalid }),
                  placeholder: _ctx.$t('user.street')
                }, null, 8, ["modelValue", "class", "placeholder"])
              ]),
              _: 1
            }, 8, ["validations"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("user.post_code")) + " * ", 1),
            _createVNode(_component_Validation, {
              class: "w-100",
              validations: _ctx.v.postalCode,
              "show-errors": true
            }, {
              default: _withCtx(({ invalid }) => [
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.data.postalCode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.postalCode) = $event)),
                  trim: "",
                  class: _normalizeClass({ 'is-invalid': invalid }),
                  placeholder: _ctx.$t('user.post_code')
                }, null, 8, ["modelValue", "class", "placeholder"])
              ]),
              _: 1
            }, 8, ["validations"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("user.city")) + " * ", 1),
            _createVNode(_component_Validation, {
              class: "w-100",
              validations: _ctx.v.location,
              "show-errors": true
            }, {
              default: _withCtx(({ invalid }) => [
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.data.location,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.location) = $event)),
                  trim: "",
                  class: _normalizeClass({ 'is-invalid': invalid }),
                  placeholder: _ctx.$t('user.city')
                }, null, 8, ["modelValue", "class", "placeholder"])
              ]),
              _: 1
            }, 8, ["validations"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("user.birth_date")) + " * ", 1),
            _createVNode(_component_Validation, {
              class: "w-100",
              validations: _ctx.v.birthDate,
              "show-errors": true
            }, {
              default: _withCtx(({ invalid }) => [
                _createVNode(_component_FscDatepicker, {
                  modelValue: _ctx.data.birthDate,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.birthDate) = $event)),
                  locale: "de-DE",
                  "is-invalid": invalid,
                  placeholder: _ctx.$t('user.birth_date')
                }, null, 8, ["modelValue", "is-invalid", "placeholder"])
              ]),
              _: 1
            }, 8, ["validations"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("user.birth_place")) + " * ", 1),
            _createVNode(_component_Validation, {
              class: "w-100",
              validations: _ctx.v.birthPlace,
              "show-errors": true
            }, {
              default: _withCtx(({ invalid }) => [
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.data.birthPlace,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.birthPlace) = $event)),
                  trim: "",
                  class: _normalizeClass({ 'is-invalid': invalid }),
                  placeholder: _ctx.$t('user.birth_place')
                }, null, 8, ["modelValue", "class", "placeholder"])
              ]),
              _: 1
            }, 8, ["validations"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "further-btn mb-3 mr-2",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args)))
            }, "Zurück"),
            _createElementVNode("button", {
              class: "further-btn mb-3 ml-2",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)))
            }, "Weiter")
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}